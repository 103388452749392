export default function newsletterForm() {
  submitNewsletterForm()
}

function submitNewsletterForm() {  
  $('#newsletter_form').on('submit', function(e) {
    e.preventDefault();
    var _this = $(this);

    _this.find('.button').addClass('loading');

    var _name = $(this).find('#full_name').val();
    var _email = $(this).find('#email_address').val();
    var _hear = $(this).find('#find_us :selected').val();
    var _policy = false;
    var _optin = false;
    var _captcha = grecaptcha.getResponse(newsletterRecaptcha);


    if ($('#newsletter-policy-opt-in').is(':checked') ) {
      _policy = true;
    }

    if ( $('#newsletter-opt-in').is(':checked') ) {
      _optin = true;
    }

    var data = {
      action: 'newsletter_form',
      name: _name,
      email: _email,
      hear: _hear,
      policy: _policy,
      optin: _optin,
      captcha: _captcha
    }

    jQuery.post(window.location.origin + "/wp-admin/admin-ajax.php", data, function (response) {


      if (response == 'recaptcha') {
        $('.newsletter-recaptcha-error').html('Please validate Recaptcha').addClass('active');
      } else {
        $('#newsletter_form').fadeOut(function() {
          if (response == 'Contact updated' || response == 'Contact added') {
            $('.newsletter-message').html('<p>Thank you for signing up for more information about Temple and we can’t wait to share our journey to creating the newest district in Leeds with you.</p><p>This is one of Leeds’ most exciting developments and now you’ll be the very first to find out the latest on our events updates.</p><p>So keep an eye on your inbox and the other on the skyline. #LS1.1 #TempleLeeds</p>').addClass('active');
          } else {
            $('.newsletter-message').html('An error has occurred').addClass('active');
          }
        });
      }

      _this.find('.button').removeClass('loading');

      return false;
    })
    .done(function () {
      //
    });
  });
  
  $('#events_form').on('submit', function(e) {
    e.preventDefault();
    var _this = $(this);

    _this.find('.button').addClass('loading');

    var _name = $(this).find('#full_name').val();
    var _email = $(this).find('#email_address').val();
    var _hear = $(this).find('#find_us :selected').val();
    var _policy = false;
    var _optin = false;
    var _captcha = grecaptcha.getResponse(eventRecaptcha);

    if ($('#event-policy-opt-in').is(':checked')) {
      _policy = true;
    }

    if ($('#event-opt-in').is(':checked')) {
      _optin = true;
    }

    var data = {
      action: 'events_form',
      name: _name,
      email: _email,
      hear: _hear,
      policy: _policy,
      optin: _optin,
      captcha: _captcha
    }

    jQuery.post(window.location.origin + "/wp-admin/admin-ajax.php", data, function (response) {

      if (response == 'recaptcha') {
        $('.events-recaptcha-error').html('Please validate Recaptcha').addClass('active');
      } else {
        $('#events_form').fadeOut(function() {
          if (response == 'Contact updated' || response == 'Contact added') {
            $('.events-message').html('<p>Thank you for signing up for more information about Temple and we can’t wait to share our journey to creating the newest district in Leeds with you.</p><p>This is one of Leeds’ most exciting developments and now you’ll be the very first to find out the latest on our events updates.</p><p>So keep an eye on your inbox and the other on the skyline. #LS1.1 #TempleLeeds</p>').addClass('active');
          } else {
            $('.events-message').html('An error has occurred').addClass('active');
          }
        });
      }


      _this.find('.button').removeClass('loading');

      return false;
    })
    .done(function () {
      //
    });
  });

}
