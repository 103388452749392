export default function enquiryForm() {
  submitEnquiryForm()
}

function submitEnquiryForm() { 
  $('form#commercial').on('submit', function (e) {
    e.preventDefault();
    var _this = $(this);

    _this.find('.button').addClass('loading');

    var _name = $(this).find('#full_name').val();
    var _email = $(this).find('#email_address').val();
    var _postcode = $(this).find('#postcode').val();
    var _phone = $(this).find('#phone').val();
    var _when = $(this).find('#when').val();
    var _company = $(this).find('#company').val();
    var _office_space = $(this).find('#office_space').val();
    var _additional_information = $(this).find('#additional_information').val();
    var _policy = false;
    var _optin = false;
    var _captcha = grecaptcha.getResponse(commercialRecaptcha);

    if ($('#commercial-policy').is(':checked')) {
      _policy = true;
    }

    if ($('#commercial-optin').is(':checked')) {
      _optin = true;
    }

    var data = {
      action: 'enquiry_commercial_form',
      name: _name,
      email: _email,
      postcode: _postcode,
      phone: _phone,
      when: _when,
      company: _company,
      office_space: _office_space,
      additional_information: _additional_information,
      policy: _policy,
      optin: _optin,
      captcha: _captcha
    }

    jQuery.post(window.location.origin + "/wp-admin/admin-ajax.php", data, function (response) {

      if (response == 'recaptcha') {
        $('.commercial-recaptcha-error').html('Please validate Recaptcha').addClass('active');
      } else {

        if (response == 'Wrong date format') {
          $('input#when').after('<span class="validation-error">' + response + '</span>');
        } else {
          $('form#commercial').fadeOut(function () {
            if (response == 'Contact updated' || response == 'Contact added') {
              $('.enquiry-commercial-message').html('<p>Thank you for signing up for more information about Temple and we can’t wait to share our journey to creating the newest district in Leeds with you.</p><p>This is one of Leeds’ most exciting developments and now you’ll be the very first to find out the latest on our commercial updates.</p><p>So keep an eye on your inbox and the other on the skyline. #LS1.1 #TempleLeeds</p>').addClass('active');
            } else {
              $('.enquiry-commercial-message').html('An error has occurred').addClass('active');
            }
          });
        }

      }


      _this.find('.button').removeClass('loading');
      $('body, html').animate({
        scrollTop: $('.enquiry-form').offset().top
      });

      return false;
    })
      .done(function () {
        //
      });
  });

  $('form#residential').on('submit', function (e) {
    e.preventDefault();

    var _this = $(this);

    _this.find('.button').addClass('loading');

    var _name = $(this).find('#full_name').val();
    var _email = $(this).find('#email_address').val();
    var _postcode = $(this).find('#postcode').val();
    var _additional_information = $(this).find('#additional_information').val();
    var _policy = false;
    var _optin = false;
    var _captcha = grecaptcha.getResponse(residential);

    if ($('#residential-policy').is(':checked')) {
      _policy = true;
    }

    if ($('#residential-optin').is(':checked')) {
      _optin = true;
    }

    var data = {
      action: 'enquiry_residential_form',
      name: _name,
      email: _email,
      postcode: _postcode,
      additional_information: _additional_information,
      policy: _policy,
      optin: _optin,
      captcha: _captcha
    }


    jQuery.post(window.location.origin + "/wp-admin/admin-ajax.php", data, function (response) {

      if (response == 'recaptcha') {
        $('.residential-recaptcha-error').html('Please validate Recaptcha').addClass('active');
      } else {
        $('form#residential').fadeOut(function () {
          if (response == 'Contact updated' || response == 'Contact added') {
            $('.enquiry-residential-message').html('<p>Thank you for signing up for more information about Temple and we can’t wait to share our journey to creating the newest district in Leeds with you.</p><p>This is one of Leeds’ most exciting developments and now you’ll be the very first to find out the latest on our residential updates.</p><p>So keep an eye on your inbox and the other on the skyline. #LS1.1 #TempleLeeds</p>').addClass('active');
          } else {
            $('.enquiry-residential-message').html('An error has occurred').addClass('active');
          }
        });
      }


      _this.find('.button').removeClass('loading');
      $('body, html').animate({
        scrollTop: $('.enquiry-form').offset().top
      });

      return false;
    })
      .done(function () {
        //
      });
  });

}
