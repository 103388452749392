import 'lazysizes'
import Navigation from '../navigation'
import gallery from '../gallery'
import accordion from '../accordion'
import youtubeEmbed from '../youtubeEmbed'
import map from '../map'
import masterplan from '../masterplan'
import contactForm from '../contactForm'
import tabbedContent from '../tabbedContent'
import newsletterForm from '../newsletterForm'
import enquiryForm from '../enquiryForm'
import submenu from '../sub-menu'
import textImagePod from '../textImagePod'

export default function Globals () {
    Navigation()
    gallery()
    accordion()
    youtubeEmbed()
    map()
    masterplan()
    contactForm()
    tabbedContent()
    newsletterForm()
    enquiryForm()
    submenu()
    textImagePod()
}
