 export default function submenu () {
  if($('.sub-menu-header').length) {
    $('section').each(function() {
      var attr = $(this).attr('data-sub-menu-title');
      if (typeof attr === "string" && attr.length > 0) {
        var id = $(this).attr('id');
        var title = $(this).data('sub-menu-title');
        if(title.length) {
          $('.sub-menu-header__inner-items').append('<a href="#'+id+'" data-target="'+id+'">'+title+'</a>')
        }
      }
    });
  }
}

