 export default function masterplan () {
  $('.hover-pin').on('click', function (e){
    e.preventDefault();
    var selected = $(this).attr('data-target');
    $('.map-modal#'+selected).addClass('active');
  })

  $('.close-modal').on('click', function (e){
    e.preventDefault();
    $('.map-modal').removeClass('active');
  })
}
