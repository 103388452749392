export default function Navigation() {
	hamburgerNav();
	extraNav();
	returnToTop();
	openMenu();
	closeMenu();
}

function returnToTop() {
	$('.footer__lower .return-to-top').on('click', function () {
		$('html, body').animate({scrollTop: '0px'}, 1000);
	})
}

const $body = $('body');
const $headerBurger = $('.header-dropdown-burger');
const $primaryMenu = $('#primary-menu');

function openMenu() {
	$body.addClass('menu-open');
	$headerBurger.removeClass('open-primary-menu').addClass('close-primary-menu');
	$primaryMenu.find('.header-dropdown-burger .text .more').html('Less');

}

function closeMenu() {
	$body.removeClass('menu-open');
	$headerBurger.addClass('open-primary-menu').removeClass('close-primary-menu');
	$primaryMenu.find('.header-dropdown-burger .text .more').html('More');


}

function hamburgerNav() {
	$('.mobile-toggle').on('click', function (e) {
		e.preventDefault();
		if ($body.hasClass('menu-open')) {
			closeMenu();
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		} else {
			openMenu();
			const currentPosition = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `-${currentPosition}px`;
		}
	});
}

function extraNav() {
	$headerBurger.on('click', function (e) {
		e.preventDefault();
		if ($body.hasClass('menu-open')) {
			closeMenu()
		} else {
			openMenu()
		}
	});
}