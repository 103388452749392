export default function youtubeEmbed () {
  $(document).on('click', '.yt-thumb', function (e) {
    e.preventDefault();
    $(this).addClass('hide-thumb');
    $(this).parents('.youtube-player-wrapper').find('iframe').attr('src', $(this).parents('.youtube-player-wrapper').find('iframe').attr('src') + '?autoplay=1');
    if ($(this).parents('.youtube-player-wrapper').find("video").length > 0) {
      var video = $(this).parents('.youtube-player-wrapper').find('video');
      video.get(0).play();
    }
  });

  $(document).on('click', '.video-thumb', function (e) {
    e.preventDefault();
    $(this).addClass('hide-thumb');
    $(this).parents('.video-player-inner').find('iframe').attr('src', $(this).parents('.youtube-player-wrapper').find('iframe').attr('src') + '?autoplay=1');
    if ($(this).parents('.video-player-inner').find("video").length > 0) {
      var video = $(this).parents('.video-player-inner').find('video');
      video.get(0).play();
    }
  });
}
