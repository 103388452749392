export default function tabbedContent () {
  $('.enquiry-form__tabs a').on('click', function (e) {
    e.preventDefault();
    var target = $(this).data('tab');
    $('.enquiry-form__form').removeClass('active');
    $('.enquiry-form__tabs a').removeClass('active');
    $(this).addClass('active');
    $('.enquiry-form__form#'+target).addClass('active');
  })  
}
