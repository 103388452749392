import 'slick-carousel'

export default function gallery () {
  galleryHandler()
}

function galleryHandler () {
  var galleryPods = $('.gallery-pod');
  galleryPods.each(function() {
    var slidesyncID1 = ("."+$(this).find('.slideshow__container').data('slide-sync'));
    var slidesyncID2 = ("."+$(this).find('.caption-slider').data('slide-sync'));
    var slider = $(this).find('.slideshow__container');
    var arrows = $(this).find('.pod__gallery-arrows');
    var statustext = $(this).find('.pagingInfo');
    var caption = $(this).find('.caption-slider');
    slider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
      var i = (currentSlide ? currentSlide : 0) + 1;
      statustext.text(i + ' / ' + slick.slideCount);
    });
    slider.slick({
      dots: false,
      prevArrow: '<button class="arrow"><svg><use xlink:href="#sprite-gallery"></use></svg></button>',
      nextArrow: '<button class="arrow"><svg><use xlink:href="#sprite-gallery"></use></svg></button>',
      appendArrows: arrows,
      speed: 500,
      fade: true,
      adaptiveHeight: true,
      cssEase: 'linear',
      asNavFor: slidesyncID2
    });

    caption.slick({
      dots: false,
      arrows: false,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      asNavFor: slidesyncID1
    });

    $(window).on('resize orientationchange', function () {
      slider.slick('refresh');
    });
  });
}
