export default function accordion () {
  const $accordionItem = $('.accordion__item')

  $accordionItem.on('click', function () {
    let $this = $(this)
    let $accordionContent = $(this).find('.accordion__content')

    $this.toggleClass('-is-active')
    $accordionContent.attr('aria-expanded', !$this.hasClass('-is-active'))
  })
}